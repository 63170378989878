@use '@angular/material' as mat;

@mixin event-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .event-table-container {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 0;

    .table-header-container {
      background-color: mat.get-color-from-palette($primary, 100);
      padding-left: 1rem;
      padding-right: 1rem;

      .middle-content-container {
        visibility: hidden;
        display: none;
        height: 0;
      }
    }

    mat-table {
      .mat-stroked-button:not(.mat-button-disabled) {
          color: mat.get-color-from-palette($accent, default);
          border-color: mat.get-color-from-palette($zonar-gray, 500);
      }
      .mat-column-assetName {
        max-width: 10rem;
        .asset-name-text {
          word-break: break-word;
          line-height: normal;
        }
      }
    }

  }
  .zui-mobile-table-container {
    .zui-mobile-card-container {
        // Custom css to right align asset name component in mobile table. 
        zui-asset-name {
            float: right;
            .asset-icon {
                width: unset;
            }
        }
    }
  }

}
