@use '@angular/material' as mat;

@mixin asset-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $disabled-background: #e0e0e0;
  $disabled-color: #9e9e9e;

  .view-events-link {
    text-decoration: none;
    font-weight: 700;
  }
  .asset-table-container {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 0;

    .mat-card-header .mat-card-title {
      padding: 0 1rem;
    }

    .table-header-container {
      background-color: mat.get-color-from-palette($primary, 100);
      padding-left: 1rem;
      padding-right: 1rem;

      .middle-content-container {
        visibility: hidden;
        display: none;
        height: 0;
      }
    }

    .mat-table {
      min-height: 10rem;
      .mat-header-row {
        border-bottom-width: 2px;
      }
      .mat-header-cell {
        text-transform: uppercase;
        border-bottom: unset;
        text-align: left;
      }

      .mat-sort-header-content {
        text-transform: uppercase;
        text-align: left;
      }

      .mat-column-assetName {
        max-width: 10rem;
        .asset-name-text {
          word-break: break-word;
          line-height: normal;
        }
      }
    }

    .mat-error {
      background-color: transparent;

      .error-card {
        margin: 0;
        @include mat.elevation(0);
      }
    }

    .mat-paginator-container {
      padding: 0 1.5rem;
    }

    .spinner-container {
      display: flex;
      justify-content: center;
      transform: translateY(10vh);

      .mat-progress-spinner {
        position: absolute;
      }
    }

    .scan-button-enabled {
      background-color: mat.get-color-from-palette($accent, default);
      color: mat.get-color-from-palette($accent, default-contrast);
      margin-right: 1rem;
      line-height: 28px;
    }

    .scan-button-disabled {
      background-color: $disabled-background;
      color: $disabled-color;
      cursor: not-allowed;
      opacity: 0.5;
      line-height: 28px;
    }

    .scan-button-column {
      font-weight: 700;
    }

  }
  .zui-mobile-table-container {
    .zui-mobile-card-container {
        // Custom css to right align asset name component in mobile table. 
        zui-asset-name {
            float: right;
            .asset-icon {
                width: unset;
            }
        }
    }
  } 
}
