@use "@angular/material" as mat;

@mixin bulk-asset-import-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-bulk-asset-import {
    color: mat.get-color-from-palette($primary, 900);
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-2);
      margin-bottom: 1rem;
    }

    .description {
      @include mat.typography-level($zonar-default-typography, body-1);

      .url-file-sample {
        @include mat.typography-level($zonar-default-typography, body-2);
      }
    }

    .status-message {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    .result-column {
      @include mat.typography-level($zonar-default-typography, body-2);
    }

    a:link,
    a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }
  }
  .notification-container {
    .action-button,
    .notification-btn {
      @include mat.typography-level($zonar-default-typography, body-2);
      padding: 4px 12px !important;
      color: mat.get-color-from-palette($accent, default);
      background-color: mat.get-color-from-palette($zonar-gray, 50);
      border: none;
      cursor: pointer;
    }
  }
}
