@use '@angular/material' as mat;

@mixin no-records-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .no-records-container {
    text-align: center;

    .no-records-headline {
      font-size: mat.font-size($zonar-default-typography, 'subheading-2');
      padding-top: 2.5rem;
    }

    .no-records-subtext {
      padding-top: 0.5rem;
    }
  }
}