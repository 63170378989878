@use '@angular/material' as mat;

@import 'src/app/components/asset-table/asset-table.theme';
@import 'src/app/components/card/card.theme';
@import 'src/app/components/dialog/dialog.theme';
@import 'src/app/components/emissions-event-table/emissions-event-table.theme.scss';

@mixin overview-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .overview-container {
    @include card-theme($theme);
    @include dialog-theme($theme);
    @include asset-table-theme($theme);
    @include event-table-theme($theme);

    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }
    
    .data-viz-wrapper {
      margin-bottom: 3rem;

      .data-viz-child {
        .mat-card {
          margin: 0;
          height: 100%;
        }
      }
    }

    .skeleton-bar {
      padding-left: 3%;
    }
    .mat-tab-nav-panel {
      position: relative;
    }
    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
    .manage-assets-btn {
      line-height: 2rem;
      padding: 0 1.2rem;
      font-size: 0.875rem;
      mat-icon {
        font-size: 1.25rem;
      }
    }
  }

}