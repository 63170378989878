@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';

@import '../app/containers/overview-container/overview-container.theme';
@import '../app/containers/status-container/status-container.theme';
@import '../app/components/page-title/page-title.theme';
@import '../app/components/no-records/no-records.theme';
@import '../app/components/mobile-drawer/mobile-drawer.component.theme';
@import "../app/components/bulk-asset-import/bulk-asset-import.theme";
@import "../app/components/bulk-asset-import-requirements/bulk-asset-import-requirements.theme";
@import "../app/components/import-guide/import-guide.theme";
@import "../app/components/file-upload/file-upload.theme";
@import '../app/components/scan-result/scan-result.theme';

@mixin apply-zonar-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);

  @include page-title-theme($theme);
  @include overview-theme($theme);
  @include no-records-theme($theme);
  @include mobile-drawer-theme($theme);
  @include bulk-asset-import-dialog-theme($theme);
  @include scan-result-dialog-theme($theme);
  @include import-guide-theme($theme);
  @include bulk-asset-import-requirements-theme($theme);
  @include file-upload-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  // TODO: temporary change, will be removed in future.
  .asset-filter .ui-tree-dropdown-form {
    width: 12.75rem;
    max-width: unset;
  }
}


.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.content-container {
  padding: 2rem;
}

// This should be applied globally for required field.
.required:after {
  content: " *";
  color: $critical;
}


// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
  .mat-option-text {
    font-size: medium;
  }

  .mat-select-panel {
    max-height: fit-content;
  }
}
