@use "@angular/material" as mat;

@mixin scan-result-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  // Define disabled colors based on your theme or preferences
  $disabled-background: #e0e0e0; // Adjust as needed
  $disabled-color: #9e9e9e; // Adjust as needed

  .scan-result {
    color: mat.get-color-from-palette($primary, 900);
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    mat-list-item {
      @include mat.typography-level($zonar-default-typography, body-1); // Adjust the typography level as needed

      // If you need to target the individual spans inside the list item for more control:
      span[matListItemTitle], span[matListItemLine] {
        // Apply specific font styles if needed
      }
    }

    .description {
      @include mat.typography-level($zonar-default-typography, body-1);

      .url-file-sample {
        @include mat.typography-level($zonar-default-typography, body-2);
      }
    }

    .status-message {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    .result-column {
      @include mat.typography-level($zonar-default-typography, body-2);
    }

    a:link,
    a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }

    .button-container {
      button {
        background-color: mat.get-color-from-palette($accent, default);
        color: mat.get-color-from-palette($accent, default-contrast);
        min-width: 8em;
        margin-right: 1rem;

        // Specifically target the mat-button-disabled class
        &.mat-button-disabled, &:disabled {
          background-color: $disabled-background; // Use a suitable color for disabled state
          color: $disabled-color; // Change text color for disabled state
          cursor: not-allowed; // Optionally change cursor to indicate non-interactivity
          opacity: 0.5;
        }
      }
    }
  }
}
